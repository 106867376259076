<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint quibusdam mollitia ducimus quidem culpa perspiciatis accusamus dolor eius repudiandae, placeat dolorem assumenda a sunt totam veniam nihil iusto enim molestiae eos blanditiis? Accusantium quisquam delectus quia ea, voluptates sed? Quod.</p>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'homePage',
  components: {
    // HelloWorld
  }
}
</script>
